<template>
  <app-module-view>
    <template slot="body">
      <div v-if="dataLoaded" class="row">
        <div v-for="(block, index) in blocks" :key="`item-${index}`" class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4>{{ block.title }}</h4>
              <p v-if="!block.poll">{{ $t('poll.management.no_poll_selected') }}</p>
              <p v-else>{{ block.pollInfo.title }} (id: {{ block.pollInfo.id }})</p>
              <button
                v-if="isCreateButtonVisible"
                @click="showPollNewModalForBlock(block)"
                class="btn btn-success"
              >
                {{ $t('poll.management.create_poll') }}
              </button>
              <button
                v-if="isAssignPollButtonVisible(block)"
                @click="showPollSelectModalForBlock(block)"
                class="btn btn-skype"
              >
                {{ $t('poll.management.assign_poll') }}
              </button>
              <app-button-delete
                v-if="isDeleteButtonVisible(block)"
                @deleteRecord="removePoll(block)"
              >
              </app-button-delete>
            </div>
          </div>
        </div>
      </div>
      <app-preloader v-if="callingAPI || !dataLoaded"></app-preloader>
      <app-poll-new-modal
        v-if="pollNewModal"
        @close="closePollNewModalForBlock"
        @set-data="setPoll"
      >
      </app-poll-new-modal>
      <app-poll-select-modal
        v-if="pollSelectModal"
        @close="closePollSelectModalForBlock"
        @set-data="setPoll"
      >
      </app-poll-select-modal>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import PollNewModal from '../../components/poll/PollNewModal'
import PollSelectModal from '../../components/poll/PollSelectModal'
import NotifyService from '../../services/NotifyService'
import ButtonDelete from '../../components/shared/ButtonDelete'
import Preloader from '../../components/preloader/Preloader'
import { mapState } from 'vuex'
import PermissionService from '@/services/PermissionService'
import { PERMISSION_TRANSFER_ASSIGN } from '@/model/ValueObject/UserPermissions'

export default {
  name: 'PollManagementView',
  data () {
    return {
      dataLoaded: false,
      pollNewModal: false,
      pollSelectModal: false,
      selectedPollBlock: null,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.POLL_MANAGEMENT_PERMISSIONS
    }
  },
  components: {
    appModuleView: ModuleView,
    appPollNewModal: PollNewModal,
    appPollSelectModal: PollSelectModal,
    appButtonDelete: ButtonDelete,
    appPreloader: Preloader
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createPollButton)
    },
    blocks () {
      return this.$store.getters['pollBlock/all']
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    showPollNewModalForBlock (pollBlock) {
      this.$store.dispatch('pollBlock/fetchOne', pollBlock.id)
        .then(() => {
          this.selectedPollBlock = this.$store.getters['pollBlock/detail']
          this.pollNewModal = true
        })
    },
    closePollNewModalForBlock () {
      this.pollNewModal = false
    },
    showPollSelectModalForBlock (pollBlock) {
      this.$store.dispatch('pollBlock/fetchOne', pollBlock.id)
        .then(() => {
          this.selectedPollBlock = this.$store.getters['pollBlock/detail']
          this.pollSelectModal = true
        })
    },
    closePollSelectModalForBlock () {
      this.pollSelectModal = false
    },
    isDeleteButtonVisible (block) {
      return block?.poll && this.hasPermission(this.requiredPermissions.deletePollButton)
    },
    isAssignPollButtonVisible (block) {
      return block?.identifier === 'trend_hp_poll' && this.hasPermission(PERMISSION_TRANSFER_ASSIGN)
    },
    removePoll (pollBlock) {
      this.$store.dispatch('pollBlock/fetchOne', pollBlock.id)
        .then(() => {
          this.selectedPollBlock = this.$store.getters['pollBlock/detail']
          this.selectedPollBlock.identifier = this.selectedPollBlock.identifier.trim()
          this.selectedPollBlock.poll = null
          this.$store.dispatch('pollBlock/update', this.selectedPollBlock)
            .then(() => {
              if (this.$store.getters['pollBlock/error'] === null) {
                NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
                this.getAllBlocks()
              } else {
                NotifyService.setErrorMessage(this.$store.getters['pollBlock/error'])
              }
            })
            .catch(error => console.log(error))
        })
    },
    setPoll (poll) {
      this.selectedPollBlock.poll = poll.id
      this.selectedPollBlock.identifier = this.selectedPollBlock.identifier.trim()
      delete this.selectedPollBlock.pollInfo
      this.$store.dispatch('pollBlock/update', this.selectedPollBlock)
        .then(() => {
          if (this.$store.getters['pollBlock/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
            this.getAllBlocks()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['pollBlock/error'])
          }
        })
        .catch(error => console.log(error))
    },
    getAllBlocks () {
      this.dataLoaded = false
      this.$store.dispatch('pollBlock/fetchAll')
        .then(() => {
          this.dataLoaded = true
        })
    }
  },
  created () {
    this.getAllBlocks()
  }
}
</script>
